import * as React from "react"
import styled from 'styled-components/macro'
import DolomiteLogo from '../../images/Dolomite-logo.png'

const FooterWrapper = styled.div`
  height: 36px;
  width: calc(100% - 48px);
  display: flex;
  justify-content: space-between;
  padding: 4px 24px;
  color: #f9f9f9;
  background: none;
  position: absolute;
  bottom: 0;
`

const LogoWrapper = styled.div`
  height: 100%;
  width: fit-content;
  display: inline-block;
  line-height: 30px;
  margin-top: -8px;
  
  img {
    height: 30px;
    width: auto;
  }
`

const DocsWrapper = styled.div`
  position: absolute;
  bottom: 95px;
  right: 23px;
  
  a:hover {
    color: white !important;
    transition: color 0.2s ease-in-out;
  }
`

const BrandAssetsWrapper = styled.div`
  position: absolute;
  bottom: 70px;
  right: 23px;
  
  a:hover {
    color: white !important;
    transition: color 0.2s ease-in-out;
  }
`

const TOSWrapper = styled.div`
  position: absolute;
  bottom: 45px;
  right: 23px;
  
  a:hover {
    color: white !important;
    transition: color 0.2s ease-in-out;
  }
`

const NavButtons = styled.div`
  width: fit-content;
  height: 100%;
`

const LaunchAppButton = styled.div`
  background-color: #8fc942;
  border-radius: 5px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
`

// markup
const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <FooterWrapper>
      <LogoWrapper><img src={DolomiteLogo} alt={'Dolomite logo'} /></LogoWrapper>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <DocsWrapper><a href={'http://docs.dolomite.io'} target={'_blank'} style={{ color: '#606375', textDecoration: 'none' }}>Docs</a></DocsWrapper>
      <BrandAssetsWrapper><a href={'/brand-assets'} target={'_blank'} style={{ color: '#606375', textDecoration: 'none' }}>Brand Assets</a></BrandAssetsWrapper>
      <TOSWrapper><a href={'/Dolomite-Terms-of-Service.pdf'} target={'_blank'} style={{ color: '#606375', textDecoration: 'none' }}>Terms of Service</a></TOSWrapper>
      <div>© Dolomite {currentYear}</div>
    </FooterWrapper>
  )
}

export default Footer
