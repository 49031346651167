import Navbar from "../Common/Navbar";
import HomePage from "../HomePage/HomePage";
import Footer from "../Common/Footer";
import * as React from "react";
import DesktopGuide from './Desktop.mdx'
import { Navigation } from './guide'

const DESKTOP = 0
const MOBILE = 1

const QuickStartGuide = () => {
  const [selectedTab, setSelectedTab] = React.useState(0)

  return (
    <div>
      <DesktopGuide />
    </div>
  )
}

export default QuickStartGuide